import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import Link from '../../components/common/Link.component';
import Text from '../../components/common/text.component';
import StockTable from '../../components/admin/products/detail/StockTable.component';
import { useTheme } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoginSection from '../../components/header/LoginSection.component';
import Button from '../../components/common/button.component';
import CreateIcon from '@material-ui/icons/Create';
import Notification from '../../components/common/Notification';
import CarPhotoGalery from '../../components/CarDetail/car-photos-galery/CarPhotoGalery.component';
import InformationCard from '../../components/CarDetail/informationCard/InformationCard.component';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_BRAND_LOGO, GET_PRODUCT } from '../../queries';
import { UPDATE_PRODUCT } from '../../mutations';
import LoadingModal from '../../components/common/LoadingModal.component';
import { formatPrice } from '../../utils/currency.utils';
import InformationCardUsado from '../../../src/components/admin/products/detail/InformationCardUsado.component';
import { formaterNumber } from '../../../src/utils/currency.utils';
import { goToAdminPanel } from '../../api/navigation';

const TITLE = 'Producto';
const ALERT_TEXT_ONE = 'Sólo se publicará una unidad por color.';
const ALERT_TEXT_TWO = 'Los usuarios particulares visualizarán el precio de venta.';
const PARTICULAR = 'PARTICULAR';

const GetMessageAlert = () => {
  const classes = useStyles();
  return <ul className={classes.messageNotificationtext}>
    <li>{ALERT_TEXT_ONE}</li>
    <li>{ALERT_TEXT_TWO}</li>
  </ul>;
};
// eslint-disable-next-line max-len
const NULL_WARNING_TEXT =
  'Este producto no tiene color en su stock y no se publicará correctamente, cargue el color del stock desde Asofix.';
const ERROR_MESSAGE =
  '¡ Lo sentimos ! Ocurrió un error inesperado, intentá nuevamente más tarde.';
const CATALOG_ERROR_MESSAGE =
  '¡ Lo sentimos ! Ocurrió un error inesperado, intentá nuevamente.';
const ERROR_MESSAGE_MODAL = 'Por favor, intentalo de nuevo.';
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    gap: '2.5rem',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1.6),
    paddingBottom: theme.spacing(0.8),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  stretch: {
    [theme.breakpoints.up('xl')]: {
      width: '41vw',
    },
    [theme.breakpoints.down('lg')]: {
      width: '37vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '36vw',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    }
  },
  spacing: {
    paddingTop: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    alignItems: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '35rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '2rem 1rem 0',
  },
  sectionClient: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '2rem 1rem 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionData: {
    paddingBottom: '1.8rem',
    width: '100%',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  pending: {
    color: theme.palette.error.main,
  },
  approved: {
    color: theme.palette.success.main,
  },
  cancelled: {
    color: theme.palette.grayScale.g400,
  },
  inReview: {
    color: theme.palette.info.main,
  },
  link: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'center',
    '& button': {
      width: '100%',
    },
  },
  editBtn: {
    padding: '0 2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cancelBtn: {
    marginRight: '1rem',
  },
  buttonsContainer: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
  buttonIcon: {
    height: '0.9rem',
    marginLeft: '-10px',
  },
  priceContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  carouselContainer: {
    maxWidth: '40vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    },
  },
  messageNotificationtext: {
    marginBottom: 0,
    '& li': {
      marginBottom: 0,
    }
  },
  adicionalContainerModalLoading: {
    minHeight: '21.5rem',
    paddingBottom: 28
  }
}));

const ProductDetailContainer = ({
  productId: selectedProduct,
  onGoBack,
  user,
  onSave,
}) => {
  const [stockData, setStockData] = useState({});
  const [currency, setCurrency] = useState('$');
  const [editing, setEditing] = useState(false);
  const [updatedSuccess, setSuccessfullyUpdate] = useState(false);
  const [showLoadingModal, setshowLoadingModal] = useState(false);
  const [logo, setLogo] = React.useState('');
  const [hasNullColors, setHasNullColors] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [stockDataOriginal, setStockDataOriginal] = useState({});

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id: selectedProduct },
    onCompleted: (data) => {
      const newStockData = {};
      const stockDataOriginal = {};
      for (let productStock of data.product.stock) {
        if (productStock.color !== null) {
          const id = productStock.id;
          newStockData[id] = {
            id,
            price: productStock.price,
            stockIds: productStock.stockIds,
          };
          stockDataOriginal[id] = {
            id,
            price: productStock.price,
          };
        } else {
          setHasNullColors(true);
        }
      }
      setStockData(newStockData);
      setStockDataOriginal(stockDataOriginal);
      setCurrency(data.product.currency_symbol);
    },
    fetchPolicy: 'network-only',
  });
  const product = data?.product || {};
  const brandName = data?.product.brand.name;

  useQuery(GET_BRAND_LOGO, {
    skip: !brandName,
    variables: { brand: brandName },
    onCompleted: (onCompleteData) => setLogo(onCompleteData?.brandLogo),
  });

  const [updateProduct, { loading: updating, error: updatedError }] =
    useMutation(UPDATE_PRODUCT, {
      refetchQueries: [
        {
          query: GET_PRODUCT,
          variables: { id: selectedProduct },
        },
      ],
      onCompleted: () => {
        setEditing(false);
        setSuccessfullyUpdate(true);
        setshowLoadingModal(true);
      },
      onError: () => {
        setshowLoadingModal(true);
        setSuccessfullyUpdate(false);
      },
    });

  const handleOnVisibleChanged = (visible, id, type) => {
    const newStockData = {
      ...stockData,
    };
    const keyUpdateType = type === PARTICULAR ? 'statePublic' : 'stateReseller';

    if (newStockData.hasOwnProperty(id)) {
      newStockData[id] = {
        ...newStockData[id],
      };
      newStockData[id][keyUpdateType] = visible;
    } else {
      newStockData[id] = {
        id,
      };
      newStockData[id][keyUpdateType] = visible;
    }
    setStockData(newStockData);
  };

  const handleOnPriceChanged = (price, id) => {
    price = parseInt(price.split(' ')[1]);
    const newStockData = {
      ...stockData,
    };
    if (price === 0) {
      price = product.price;
    }
    if (newStockData.hasOwnProperty(id)) {
      newStockData[id] = {
        ...newStockData[id],
        price,
      };
    } else {
      newStockData[id] = {
        id,
        price,
      };
    }
    setStockData(newStockData);
  };

  function handleEditButtonClick() {
    setEditing(true);
  }

  function handleCancelButtonClick() {
    setEditing(false);
  }

  function handleCloseModalClick() {
    setshowLoadingModal(false);
    setSuccessfullyUpdate(false);
    goToAdminPanel();
  }

  function handleSaveButtonClick() {
    const updatedStocks = Object.assign([], Object.values(stockData));
    let dataToUpdate = [];
    for (let stockToUpdate of updatedStocks) {

      let stocksByStocksIds = stockToUpdate.stockIds.map((stockId) => {
        let stockWithIdOkey = Object.assign({}, stockToUpdate);
        stockWithIdOkey.isPriceChanged = stockDataOriginal[stockId] && stockDataOriginal[stockId].price !== stockToUpdate?.price;
        stockWithIdOkey.id = stockId;
        stockWithIdOkey.visible = stockToUpdate.visible;
        stockWithIdOkey.stateReseller = stockToUpdate.stateReseller;
        stockWithIdOkey.statePublic = stockToUpdate.statePublic;
        return stockWithIdOkey;
      });
      dataToUpdate = dataToUpdate.concat(stocksByStocksIds);
    }
    dataToUpdate = dataToUpdate.map((dataItem) => {
      delete dataItem.stockIds;
      return dataItem;
    });
    updateProduct({
      variables: {
        productId: selectedProduct,
        updatedStocks: dataToUpdate,
      },
    });
  }

  if (loading) return <LoadingModal />;

  if (error)
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
      >
        <Box
          padding="4rem"
          textAlign="center"
          fontSize="1rem"
          display="box"
          maxWidth="30rem"
        >
          <Text light>{ERROR_MESSAGE}</Text>
        </Box>
      </Box>
    );

  const Buttons = () => (
    <Box width="100%" className={classes.buttonsContainer}>
      {editing ? (
        <>
          <Button
            outline
            className={classes.cancelBtn}
            onClick={() => handleCancelButtonClick()}
          >
            Cancelar
          </Button>
          <Button primary onClick={() => handleSaveButtonClick()}>
            Guardar
          </Button>
        </>
      ) : (
        <Button
          primary
          className={classes.editBtn}
          onClick={() => handleEditButtonClick()}
        >
          <CreateIcon className={classes.buttonIcon} />
          Editar
        </Button>
      )}
    </Box>
  );
  const is0Km = product.is0km;

  return (
    <>
      {isMobile && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onGoBack}
              className={classes.menuButton}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Text light small>
              {TITLE}
            </Text>
          </Toolbar>
          <LoginSection isDesktop isLoggedIn profile={user.profile} />
        </AppBar>
      )}
      <Box className={classes.mainContainer}>
        <Box className={classes.stretch}>
          <Box className={classes.link}>
            <Link inverted onClick={onGoBack}>
              <Box display="flex" alignItems="center">
                <ArrowBackIosIcon style={{ fontSize: 10 }} />
                <Text small>Volver</Text>
              </Box>
            </Link>
          </Box>
          <Notification text={<GetMessageAlert />} />
          <Box className={classes.carouselContainer}>
            <CarPhotoGalery photos={product.images} thumbnails={false} />
          </Box>
          <Box className={classes.spacing}>
            <InformationCard
              versionName={product.version.name}
              brandName={product.brand.name}
              modelName={product.model.name}
              isLoggedIn={true}
              logo={logo}
            />
          </Box>
          {product.price && (
            <Box className={classes.priceContainer}>
              <Text xLarge bold light className={classes.spacing}>
                {formatPrice(product.price, currency)}
              </Text>
              <Text light className={classes.spacing}>
                {'*Precio de venta'}
              </Text>
            </Box>
          )}
          {!is0Km &&
            <Box className={classes.spacing}>
              <InformationCardUsado
                year={product.year}
                domain={product.domain}
                km={formaterNumber(product.km)}
                location={`${product.stock[0].branch_office}, ${product.carLot.name}`}
              />
            </Box>
          }
        </Box>
        <Box className={classes.stretch}>
          <Buttons />
          <StockTable
            editing={editing}
            data={product.stock}
            is0Km={product.is0km}
            onVisibleChange={handleOnVisibleChanged}
            onPriceChange={handleOnPriceChanged}
            currency={currency}
            priceParticular={product.price}
          />
          {hasNullColors && (
            <Box marginTop={5}>
              <Notification text={NULL_WARNING_TEXT} severity="warning" />
            </Box>
          )}
        </Box>
      </Box>
      {(updating || showLoadingModal || updatedError) && (
        <LoadingModal
          handleCloseModalClick={handleCloseModalClick}
          updatedSuccess={updatedSuccess}
          updating={updating}
          customVisibility={true}
          open={showLoadingModal || updating}
          errorMessage2={ERROR_MESSAGE_MODAL}
          succesContainerClass={classes.adicionalContainerModalLoading}
          errorContainerClass={classes.adicionalContainerModalLoading}
          loadingContainerClass={classes.adicionalContainerModalLoading}
        />
      )}
    </>
  );
};

export default ProductDetailContainer;
